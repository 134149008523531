@use '../variables/font-weight.scss';
@use '../variables/palette.scss';
@use '../variables/font-list.scss';
@use '../variables/spacing.scss';
@use '../utils/match.scss' as *;

/// @param { 1 | 2 | 3 | 4 } $level [1]
/// Die Ebene der Überschrift.
/// @param { boolean } $margin [true]
/// Flag, ob ein Margin erzeugt werden soll.
/// Der Margin kann an manchen Stellen unerwünscht sein.
@mixin heading($level: 1, $margin: true) {
  font-family: font-list.$sans-serif;
  font-size: match($level, (
    1: 42px,
    2: 22px,
    3: 18px,
    4: 16px,
  ));
  line-height: match($level, (
    1: 1.1,
    2: 1.3,
    3: 1.5,
    4: 1.3
  ));
  font-weight: match($level, (
    1: font-weight.$normal,
    2: font-weight.$semi-bold,
    3: font-weight.$semi-bold,
    4: font-weight.$bold,
  ));
  color: palette.$text-primary;
  margin-top: 0;
  @if $margin == true {
    margin-bottom: match($level, (
      1: spacing.$default,
      2: spacing.$small,
      3: spacing.$small,
      4: spacing.$small
    ));
  }
}

/// @param { 'primary' | 'passive' | 'disabled' | 'error' | 'accent' } $color ['primary']
/// Die Textfarbe.
/// @param { 'light' | 'dark' } $background ['light']
/// Anpassung der Textfarbe für hellen bzw. dunklen Hintergrund.
/// @param { 'small' | 'normal' | 'big' } $size ['normal']
/// Die Schriftgröße.
/// @param { boolean } $bolder [false]
/// Ob der Text fett(er) dargestellt werden soll.
/// Das tatsächliche Schriftgewicht kann dabei von anderen Faktoren
/// abhängig sein.
@mixin ui(
  $color: 'primary', 
  $background: 'light', 
  $size: 'normal', 
  $bolder: false
) {
  font-family: font-list.$sans-serif;
  font-size: match($size, (
    'big': 22px,
    'normal': 16px,
    'small': 14px,
  ));
  line-height: 1.6;
  font-weight: if($bolder, font-weight.$semi-bold, font-weight.$normal);
  color: match($color, (
    'primary': match($background, (
      'light': palette.$text-primary,
      'dark': palette.$text-primary-on-dark,
    )),
    'passive': match($background, (
      'light': palette.$text-passive,
      'dark': palette.$text-passive-on-dark,
    )),
    'disabled': match($background, (
      'light': palette.$text-disabled,
      'dark': palette.$text-disabled-on-dark,
    )),
    'error': match($background, (
      'light': palette.$error,
      'dark': palette.$error-on-dark
    ))
  ));
}

/// @param { 'light' | 'dark' } $background ['light']
/// Die vorhandene Hintergrundfarbe, für die das Label angepasst werden soll.
/// @param { boolean } $error [false]
/// Flag, ob das Label auf einen Fehler in der Formular-Eingabe hinweisen soll.
@mixin label($background: light, $error: false) {
  font-family: font-list.$sans-serif;
  font-size: 16px;
  line-height: 1.4;
  font-weight: font-weight.$semi-bold;
  color: match($background, (
    'light': match($error, (
      true: palette.$error,
      false: palette.$text-passive,
    )),
    'dark': match($background, (
      true: palette.$error-on-dark,
      false: palette.$text-passive-on-dark,
    )),
  ));
}

/// @param { 'light' | 'dark' } $background ['light']
/// Die vorhandene Hintergrundfarbe, für die das Label angepasst werden soll.
/// @param { 'primary' | 'accent' | 'link' | 'disabled' } $color ['primary']
/// Die Textfarbe.
/// @param { 'normal' | 'small' } $size ['normal']
/// Die Schriftgröße.
/// @param { boolean } $bolder [false]
/// Ob der Text fett(er) dargestellt werden soll.
/// Das tatsächliche Schriftgewicht kann dabei von anderen Faktoren
/// abhängig sein.
@mixin form-element(
  $background: 'light',
  $color: 'primary',
  $size: 'normal',
  $bolder: false,
) {
  font-family: font-list.$sans-serif;
  font-size: match($size, (
    'small': 14px,
    'normal': 16px,
  ));
  line-height: match($size, (
    'small': (14px * 1.4),
    'normal': (16px * 1.6),
  ));
  font-weight: if($bolder, font-weight.$semi-bold, font-weight.$normal);
  color: match($color, (
    'primary': match($background, (
      'light': palette.$text-primary,
      'dark': palette.$text-primary-on-dark,
    )),
    'disabled': match($background, (
      'light': palette.$text-disabled,
      'dark': palette.$text-disabled-on-dark,
    )),
    'accent': palette.$accent,
    'link': palette.$button-color--type-link,
  ));
}