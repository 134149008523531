@use '../../sass/variables/palette.scss';
@use '../../sass/variables/border-radius.scss';
@use '../../sass/variables/box-shadow.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/axis.scss';

.menu {
  display: inline-block;
  max-height: 100%;
  overflow: auto;
  background: palette.$view-background;
  border: 1px solid palette.$app-border-strong;
  border-radius: border-radius.$small;
  box-shadow: box-shadow.$level-5;
}
