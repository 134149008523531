@use '../../sass/variables/font-weight.scss';
@use '../../sass/variables/size.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';

.login-view {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__view-item {
    position: absolute;
    @include axis.all(0);
  }

  &__title {
    @include font-style.heading(1);
    text-align: center;
  }

  &__text {
    @include font-style.ui();
    margin-bottom: 0;
  }

  &__qr-image {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  &__list {
    @include font-style.ui();
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__form hr {
    @include axis.vertical(spacing.$big, margin);
  }

  &__form h2 {
    @include font-style.ui($bolder: true);
    margin-top: 0;
  }


  &__inputs {
    margin-top: spacing.$big;
    margin-bottom: spacing.$tiny;
  }

  &__error {
    @include font-style.ui($color: error);
    padding-top: spacing.$big;
    min-height: 49px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-start;
    padding-top: spacing.$big;
  }

  &__buttons > button:not(:first-child) {
    margin-left: spacing.$small;
  }

  &__sso-link {
    & + & {
      margin-top: spacing.$default;
    }
  }

  &__loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__loading-text {
    @include font-style.ui();
  }
}
