@use '../../sass/variables/palette.scss';
@use '../../sass/variables/size.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/border-radius.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/axis.scss';

.tab {
  @include font-style.ui($size: small);
  @include axis.horizontal(spacing.$default, padding);
  @include axis.vertical(spacing.$small, padding);
  @include axis.horizontal(1px solid transparent, border);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  overflow: hidden;

  &--ellipsis {
    flex: 0 1 auto;
  }

  &:not(&--current) {
    @include font-style.ui($color: passive, $size: small);
  }

  &__icon {
    flex: 0 0 auto;
    margin-right: spacing.$icon-text;
  }

  &__label {
    flex: 0 1 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // Notwendig, da `overflow: hidden;` sonst Fransen von Buchstaben ganz links
    // abschneiden kann.
    @include axis.horizontal(1px, padding);
  }

  &__count {
    margin-left: spacing.$tiny;
    background: palette.$accent;
    @include axis.horizontal(spacing.$tiny, padding);
    @include font-style.ui($size: small, $bolder: true, $background: dark);
    line-height: 20px;
    height: 20px;
    text-align: center;
    border-radius: 10px;
  }

  &__close-button {
    margin-left: spacing.$small;
    border-radius: border-radius.$small;

    &:hover {
      background-color: palette.$decent-background-hover;
    }

    &:active {
      background-color: palette.$decent-background-active;
    }
  }

  // TYPE: normal

  @at-root .tabs--type-normal .tab {
    border-top: 3px solid transparent;
  }
  
  @at-root .tabs--type-normal &:not(&--current):hover {
    background: hsla(0, 0%, 0%, 0.05);
  }

  @at-root .tabs--type-normal &:not(&--current):active {
    background: hsla(0, 0%, 0%, 0.1);
  }

  @at-root .tabs--type-normal &--current {
    background: palette.$view-background;
    border-left: 1px solid palette.$app-border;
    border-right: 1px solid palette.$app-border;
    border-top: 3px solid palette.$brand;
    box-shadow: 0 -2px 16px -8px hsla(0, 0%, 0%, 0.5);
  }

  @at-root .tabs--type-normal &:first-child {
    border-left: none;
  }

  @at-root .tabs--type-normal &:last-child {
    border-right: none;
  }

  // TYPE: compact

  @at-root .tabs--type-compact &:not(&--current) {
    border-bottom: 3px solid transparent;
  }

  @at-root .tabs--type-compact &:not(&--current):hover {
    border-bottom: 3px solid palette.$app-border;
  }
  
  @at-root .tabs--type-compact &:not(&--current):active {
    border-bottom: 3px solid palette.$app-border-strong;
  }
  
  @at-root .tabs--type-compact &--current {
    border-bottom: 3px solid palette.$brand;
  }
  
  @at-root .tabs--type-compact &:not(&--current) &__label {
    @include font-style.ui($size: small, $color: passive);
  }
  
  @at-root .tabs--type-compact &:not(&--current):active &__label {
    @include font-style.ui($size: small);
  }
  
  @at-root .tabs--type-compact &--current &__label {
    @include font-style.ui($size: small);
  }
}
