@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/axis.scss';

.product-detail-view {
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__header {
    flex: 0 0 auto;
    display: flex;
    @include axis.vertical(spacing.$default, padding);
    @include axis.horizontal(spacing.$default, padding);
  }

  &__image-container {
    flex: 0 0 auto;
    margin-right: spacing.$big;
  }

  &__image {
    display: inline-block;
    width: 120px;
  }

  &__detail-container {
    flex: 1 1 auto;
  }

  &__detail-line {
    padding-bottom: spacing.$default;
  }

  &__detail-line:last-child {
    padding-bottom: 0;
  }

  &__title {
    @include font-style.heading(2);
    margin-bottom: 0;
  }

  &__properties {
    margin-top: spacing.$default;
  }

  &__properties > div {
    margin-bottom: spacing.$tiny;
  }

  &__headline {
    @include font-style.heading(2);
    @include axis.horizontal(spacing.$default, padding);
    @include axis.vertical(spacing.$small, padding);
    border-bottom: 1px solid palette.$app-border-strong;
  }

  &__headline:not(:first-child) {
    margin-top: spacing.$huge;
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
  .asset-metadata{
    padding: spacing.$default;
    display: block;
    .form__field {
      .static {
        display: flex;
        label {
          flex: 1;
          max-width: 300px;
          border-bottom: 1px dotted palette.$brand-secondary--current;
          padding-bottom: 1px;
          font-size: 13px;
        }
        span {
          flex: 1;
          padding-left: spacing.$default;
          padding-bottom: 1px;
          font-size: 13px;
        }
      }
    }
  }
}
