@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';

.menu-title {
  background-color: palette.$decent-background;
  margin-bottom: spacing.$tiny;

  &__label {
    @include font-style.ui($size: small, $color: passive, $bolder: true);
    @include axis.horizontal(spacing.$small, padding);
    @include axis.vertical(spacing.$tiny, padding);
    border-bottom: 1px solid palette.$app-border-strong;
  }
}