@use '../../sass/variables/palette.scss';

$indicator-width: 50px;

@keyframes indicator-to-right {
  0% {
    left: -$indicator-width;
    right: 100%;
  }
  80% {
    left: 100%;
    right: -$indicator-width;
  }
  100% {
    left: 100%;
    right: -$indicator-width;
  }
}

.loading-animation {
  &__track {
    position: relative;
    height: 4px;
    width: 140px;
    margin: 10px auto;
    overflow: hidden;
    background: palette.$app-background;
  }

  &__indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    animation: {
      name: indicator-to-right;
      duration: 1.2s;
      iteration-count: infinite;
      timing-function: ease-in-out;
    };
    background: palette.$brand;
  }
}
