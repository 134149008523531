@use '../../sass/variables/palette.scss';

.list-item-asset {
  display: flex;
  padding: 0 !important;
  margin: 0 !important;

  &:hover {
    background-color: palette.$clear-background-hover;
  }
}
