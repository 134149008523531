@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/border-radius.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/focus-ring.scss' as *;

.input {
  display: flex;

  &--column {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &:not(:last-child) {
    margin-bottom: spacing.$big;
  }

  &__label {
    @include font-style.label();

    display: block;
    margin-bottom: spacing.$tiny;
  }

  &--row &__label {
    width: 20%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &--is-error &__label {
    @include font-style.label($error: true);
  }

  &__input {
    @include focus-ring();
    @include font-style.form-element();

    width: 100%;
    @include axis.horizontal(spacing.$small, padding);
    @include axis.vertical(spacing.$tiny, padding);
    border-radius: border-radius.$small;
    border: 1px solid palette.$form-border;

    &--file {
      border: unset !important;
    }
    &--radio {
      width: unset;
      padding-left: spacing.$small;
    }
  }
}
