@use '../../sass/variables/palette.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/border-radius.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/ellipsis.scss' as *;

.tree-node {
  em {
    background-color: yellow;
  }
  display: grid;
  grid-template:
    'spacer toggle label actions' auto
    / auto auto 1fr auto;
  @include axis.all(spacing.$tiny, padding);

  &--current {
    background: palette.$decent-background;
    box-shadow: 3px 0 0 palette.$brand inset;
  }

  &__spacer {
    grid-area: spacer;
  }

  &__toggle {
    grid-area: toggle;
    @include axis.all(spacing.$tiny, padding);
    border-radius: border-radius.$small;
  }

  &__toggle#{&}__toggle--visible {
    &:hover {
      background: palette.$decent-background-hover;
    }

    &:active {
      background: palette.$decent-background-active;
    }
  }

  &__label {
    grid-area: label;

    display: grid;
    grid-template:
      'icon label-text' 1fr
      / auto 1fr;
    align-items: center;

    @include font-style.ui($size: small, $bolder: true);
    @include axis.horizontal(spacing.$small, padding);
    @include axis.vertical(spacing.$tiny, padding);
    border-radius: border-radius.$small;
  }

  &--disabled &__label {
    @include font-style.ui($size: small, $bolder: true, $color: disabled);
  }

  &:not(&--disabled) &__label--hoverable {
    &:hover {
      background: palette.$decent-background-hover;
    }
  }

  &__icon {
    grid-area: icon;
  }

  &__label-text {
    grid-area: label-text;
    @include ellipsis();
  }

  &__actions {
    grid-area: actions;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: spacing.$tiny;
  }

  &__action {
    @include axis.all(spacing.$tiny, padding);
    border-radius: border-radius.$small;
  }

  &__action:not(&__action--disabled):hover {
    background: palette.$decent-background-hover;
  }

  &__action--disabled {
    opacity: 0.5;
  }
}
