@use '../../sass/variables/palette.scss';
@use '../../sass/variables/size.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/axis.scss';

.app-header {
  display: flex;
  background: palette.$brand-secondary;
  height: size.$app-header-height;

  &__logo-area {
    @include axis.horizontal(spacing.$default, padding);
    @include axis.vertical(spacing.$default, padding);
    flex: 0 0 auto;
    background: palette.$brand;
  }

  &__app-menu-area {
    display: flex;
  }

  &__user-menu-area {
    display: flex;
  }

  &__dropdown-content {
    @include axis.all(spacing.$default, 'padding');
  }
}
