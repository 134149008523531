@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/size.scss';
@use '../../sass/variables/palette.scss';

.list-item-task {
  display: flex;
  align-items: center;

  &__icon-col {
    flex: 0 0 auto;
    align-self: stretch;
    @include axis.vertical(spacing.$default, padding);
    padding-left: spacing.$default;
    padding-right: spacing.$icon-text;
  }

  &__detail-col {
    flex: 1 1 auto;
    align-self: stretch;
    @include axis.vertical(spacing.$default, padding);
  }

  @at-root .list-item--is-highlighted &__title {
    @include font-style.ui($bolder: true);
    line-height: (size.$iconset-size + 2px);
  }

  &__title-suffix {
    @include font-style.ui($color: passive);
    line-height: inherit;
    margin-left: spacing.$default;
  }

  &__meta {
    @include font-style.ui($size: small, $color: passive);
  }

  &__meta-value {
    @include font-style.ui($size: small, $color: primary, $bolder: true);

    &--inline-icon {
      @include font-style.ui($size: small, $color: primary, $bolder: true);
      display: inline-flex;
      align-items: center;
    }
  }

  &__actions-col {
    flex: 0 0 auto;
    display: flex;
    @include axis.all(spacing.$small, padding);
  }

  &__actions-col > .button:not(:last-child) {
    margin-right: spacing.$tiny;
  }

  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;

}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
