@use '../../sass/variables/border-radius.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/focus-ring.scss' as *;
@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';

.button {
  display: flex;
  align-items: center;
  @include axis.horizontal(spacing.$default, padding);
  @include axis.vertical(spacing.$tiny, padding);
  border: 1px solid transparent;
  border-radius: border-radius.$small;

  &--has-custom-color:hover {
    opacity: 0.8;
  }

  &--has-custom-color:active {
    opacity: 0.7;
  }

  &__icon--type-si {
    line-height: 0;
  }

  &__icon + &__body {
    margin-left: spacing.$icon-text;
  }

  &__icon + &__secondary-icon,
  &__body + &__secondary-icon {
    margin-left: spacing.$icon-text;
  }

  &--type-primary {
    @include font-style.form-element($background: dark, $bolder: true);
    background: palette.$button-background--type-primary;
    border-color: palette.$button-border--type-primary;

    &:disabled {
      background-color: palette.$decent-background;
      border-color: palette.$text-disabled;
      @include font-style.form-element($background: light, $bolder: true, $color: disabled);
    }

    &:not(:disabled):hover {
      background: palette.$button-background-hover--type-primary;
      border-color: palette.$button-border-hover--type-primary;
    }

    &:not(:disabled):active {
      background: palette.$button-background-active--type-primary;
      border-color: palette.$button-border-active--type-primary;
    }

    &:not(:disabled):focus {
      border-color: palette.$button-border-focus--type-primary;
    }

    @include focus-ring();
  }

  &--type-secondary {
    @include font-style.form-element($color: accent, $bolder: true);
    background: palette.$button-background--type-secondary;
    border-color: palette.$button-border--type-secondary;

    &:disabled {
      @include font-style.form-element($color: disabled, $bolder: true);
      border-color: palette.$text-disabled;
    }

    &:not(:disabled):hover {
      background: palette.$button-background-hover--type-secondary;
      border-color: palette.$button-border-hover--type-secondary;
    }

    &:not(:disabled):active {
      background: palette.$button-background-active--type-secondary;
      border-color: palette.$button-border-active--type-secondary;
    }

    @include focus-ring();
  }

  &--type-inline {
    @include font-style.form-element($bolder: true, $size: small);
    @include axis.all(spacing.$tiny, padding);
    background-color: palette.$clear-background;
    outline: none;

    &:disabled {
      @include font-style.form-element($bolder: true, $color: disabled);
    }

    &:not(:disabled):hover {
      background-color: palette.$clear-background-hover;
    }

    &:not(:disabled):active {
      background-color: palette.$clear-background-active;
    }
  }

  &--type-link {
    @include font-style.form-element($bolder: true, $color: link);
    @include axis.horizontal(0, padding);
    @include axis.vertical(spacing.$tiny, padding);
    background: palette.$button-background--type-link;
    cursor: pointer;

    &:disabled {
      @include font-style.form-element($bolder: true, $color: disabled);
    }

    &:not(:disabled):hover {
      color: palette.$button-color-hover--type-link;
    }

    &:not(:disabled):active {
      color: palette.$button-color-active--type-link;
    }
  }
}
