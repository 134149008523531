@use '../../../sass/variables/palette.scss';
@use '../../../sass/variables/spacing.scss';

.product-search-tab {
  padding: 30px 0 0 30px;

  .search-input-wrapper {
    display: flex;
    align-items: center;

    .search-form {
      width: 90%;
      height: fit-content;
    }
  }

  .search-icon {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 5px;
    border-radius: 50%;
    background-color: #f39200;
    color: white;
    width: 34px;
    height: 34px;

    .lds-spinner{
      color:white;
      transform: scale(0.3);
      left: -8.5px;
    }
    .lds-spinner div:after{
      background-color: white;
    }

  }

  .search-result-wrapper {
    display: flex;
    width: fit-content;
    flex-direction: column;
    padding: 10px 0 0 10px;

    .reset-search {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
