@use '../../../../sass/variables/spacing.scss';
@use '../../../../sass/variables/palette.scss';
@use '../../../../sass/mixins/font-style.scss';
@use '../../../../sass/mixins/axis.scss';

.product-detail-view\/task-detail {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100%;
  border-left: 1px solid palette.$app-border-strong;

  &__title {
    @include font-style.ui($size: big);
    @include axis.horizontal(spacing.$default, padding);
    @include axis.vertical(spacing.$default, padding);
    flex: 0 0 auto;
  }

  &__body {
    flex: 1 1 auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  &__preview-image-container {
    flex: 0 1 auto;
    align-self: center;
    display: flex;
    flex-direction: column;
    height: 50%;
  }

  &__preview-image-container > .image-preview {
    flex: 1 1 auto;
  }

  &__file-preview {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  &--is-centered &__file-preview {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: palette.$decent-background;
  }

  &__tabs {
    flex: 0 0 auto;
  }

  &__content {
    flex: 1 1 auto;
    overflow: auto;
    display: flex;
  }

  &__headline {
    @include font-style.ui($bolder: true);
    @include axis.horizontal(spacing.$default, padding);
  }

  &__metadata-container {
    margin-top: spacing.$default;
  }

  &__metadata {
    @include axis.all(spacing.$default, padding);
  }
}
