@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/variables/border-radius.scss';

.list-item-product {
  display: flex;
  align-items: center;

  &__image-col {
    flex: 0 0 100px;
    margin-right: spacing.$big;
  }

  &__image-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
  }

  &__image-placeholder-icon {
    color: palette.$app-border-strong;
  }

  &__title-col {
    flex: 0 1 600px;
    margin-right: spacing.$huge;
  }

  &__title {
    @include font-style.heading($level: 3);
    margin-bottom: spacing.$tiny;
  }

  &__todo-col {
    flex: 0 0 300px;
    display: flex;
    justify-content: flex-end;
    margin-right: spacing.$huge;
  }

  &__todo {
    flex: 0 0 100px;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-sizing: border-box;
    @include axis.horizontal(spacing.$small, padding);
  }

  &__todo-count {
    @include font-style.ui($size: big, $bolder: true);
  }

  &__todo-description {
    @include font-style.ui($size: small, $color: passive);
  }

  &__status-col {
    flex: 1 0 120px;
  }
}
