@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/variables/border-radius.scss';

.filter-item-badge {
  display: flex;
  align-items: center;
  border-right: 1px solid palette.$app-border;
  margin-left: spacing.$tiny;
  @include axis.horizontal(spacing.$small, padding);
  @include axis.vertical(spacing.$tiny, padding);

  @at-root :not(&) + & {
    border-left: 1px solid palette.$app-border;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__label {
    @include font-style.ui($size: small, $bolder: true);
    line-height: 1.3;
  }

  &__value {
    @include font-style.ui($size: small);
    line-height: 1.3;
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: spacing.$small;
    border-radius: border-radius.$small;
    border: 1px solid transparent;
  }

  &__action:hover {
    background: palette.$decent-background-hover;
    border-color: palette.$toolbar-button-border-hover;
  }

  &__action:active {
    background: palette.$decent-background-active;
    border-color: palette.$toolbar-button-border-active;
  }
}