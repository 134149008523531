.alert-template {
  padding: 20px;
  border-radius: 10px;
  color: white;

  &--type-info {
    background: #1E92F4;
  }
  &--type-success {
    background: #4BB543;
  }
  &--type-error {
    background: red;
  }
  &__icon {
    margin-right: 10px;
  }
}
