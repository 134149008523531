@use '../../sass/variables/spacing.scss';

.button-row {
  display: flex;

  @at-root .centered-layer__content-footer > & {
    margin-top: spacing.$big;
  }

  &--type-left {
    justify-content: start;
  }

  &--type-center {
    justify-content: center;
  }

  &--type-right {
    justify-content: end;
  }

  &--type-full-width {
    flex-direction: column;
  }

  &--type-left &__item:not(:first-child),
  &--type-center &__item:not(:first-child),
  &--type-right &__item:not(:first-child) {
    padding-left: spacing.$small;
  }

  &--type-full-width &__item:not(:first-child) {
    padding-top: spacing.$small;
  }
}