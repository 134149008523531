@use "../../sass/variables/spacing.scss";
@use "../../sass/mixins/font-style.scss";
@use "../../sass/mixins/axis.scss";
@use "../../sass/mixins/table.scss";

.key-value-table {
  @include table.reset();
  @include axis.horizontal(spacing.$default, padding);
  @include axis.vertical(spacing.$small, padding);
  
  &__key {
    width: 200px;
    @include font-style.ui($size: small, $bolder: true);
    text-align: left;
    @include axis.vertical(spacing.$tiny, padding);
  }
  
  &__value {
    @include font-style.ui($size: small);
    @include axis.vertical(spacing.$tiny, padding);
  }
}