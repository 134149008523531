@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/border-radius.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/focus-ring.scss' as *;

.item-select {
  display: block;
  width: 100%;
  position: relative;

  &:not(:last-child) {
    margin-bottom: spacing.$big;
  }

  &__button {
    width: 100%;
    border: 1px solid #999999;
    border-radius: 4px;
    padding: spacing.$tiny spacing.$small;

    &:focus {
      @include focus-ring();
      @include font-style.form-element();
      @include axis.horizontal(spacing.$small, padding);
      @include axis.vertical(spacing.$tiny, padding);
      border-radius: border-radius.$small !important;
      border: 1px solid palette.$form-border !important;
    }

    span {
      @include font-style.form-element();
      margin-right: spacing.$tiny;
    }
  }

  &__content {
    position: absolute;
    background-color: palette.$layer-background;
    width: 100%;
    @include font-style.form-element();
    @include axis.horizontal(spacing.$small, padding);
    @include axis.vertical(spacing.$tiny, padding);
    border-radius: border-radius.$small !important;
    border: 1px solid palette.$form-border !important;
    // Höhe von 2 Einträgen im Dropdown. Ab dem 3. wird gescrollt.
    max-height: 80px;
    overflow-y: auto;
    list-style-type: none;
    margin: 0;
    padding: 0;
    &:focus {
      @include focus-ring();
      border-radius: border-radius.$small !important;
      border: 1px solid palette.$form-border !important;
    }
  }
  &__content-listitem {
    padding-top: spacing.$tiny;
    padding-bottom: spacing.$tiny;
    text-align: center;
  }
  &__content-listitem--active,
  &__content-listitem:hover {
    background-color: palette.$button-background-hover--type-primary;
    border-radius: border-radius.$small;
  }
  &__content-listitem span {
    @include font-style.form-element();
    margin-right: spacing.$tiny;
  }

  &__label {
    @include font-style.label();

    display: block;
    margin-bottom: spacing.$tiny;
  }

}
