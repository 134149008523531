@use 'sfportal_sass_variables/font-weight.scss';
@use 'sfportal_sass_variables/size.scss';
@use 'sfportal_sass_variables/spacing.scss';

.environment-info {
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .env-info{
    font-size: 23pt;
    line-height: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
