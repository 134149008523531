@use './font-style.scss';
@use '../variables/spacing.scss';
@use '../variables/palette.scss';
@use './axis.scss';

@mixin counter-label(
  $background,
  $color: primary,
  $height: 22px,
) {
  display: inline-block;
  @include axis.horizontal(spacing.$tiny, padding);
  @include font-style.ui($color: $color, $size: small, $bolder: true, $background: dark);
  background-color: $background;
  height: $height;
  line-height: $height;
  border-radius: ($height * 0.5);
}
