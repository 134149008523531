@use '../../sass/mixins/font-style.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/variables/spacing.scss';

.label-item {
  display: flex;

  &__label {
    @include font-style.ui($size: small, $color: passive);
    flex: 0 0 200px;
    padding-right: spacing.$default;
  }

  &__content {
    flex: 0 0 auto;
    @include font-style.ui($size: small, $bolder: true);
  }
}