@use '../../sass/mixins/font-style.scss';

.loading-layer {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text {
    @include font-style.ui();
  }
}