@use '../../sass/variables/spacing.scss';

.toolbar-dropdown {
  position: relative;
  
  &:not(:first-child) {
    padding-left: spacing.$tiny;
  }

  &__menu {
    position: absolute;
  }

  &--align-right &__menu {
    right: 0;
  }
}