@use '../../sass/mixins/axis.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/palette.scss';

.list-item {
  @include axis.horizontal(spacing.$big, padding);
  @include axis.vertical(spacing.$big, padding);

  min-height: 74px;

  &--is-hoverable:hover {
    background-color: palette.$clear-background-hover;
  }

  &--is-hoverable:active {
    background-color: palette.$clear-background-active;
  }

  &--is-highlighted {
    background-color: palette.$highlight-background;
    box-shadow: 3px 0 0 palette.$brand inset;
  }

  &--is-current {
    background-color: palette.$decent-background;
  }

  &--is-hoverable#{&}--is-current:hover {
    background-color: palette.$decent-background-hover;
  }

  &--is-hoverable#{&}--is-current:active {
    background-color: palette.$decent-background-active;
  }

  &--is-compact {
    @include axis.all(0, padding);
    margin: 0;
  }

  & + & {
    border-top: 1px solid palette.$app-border;
  }
}
