@use '../../sass/mixins/font-style.scss';
@use '../../sass/variables/palette.scss';

.progress-bar {
  display: flex;
  flex-direction: column;
  
  &__label {
    @include font-style.ui($size: small, $bolder: true);
  }
  
  &__track {
    width: 200px;
    height: 6px;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 0 0 1px inset palette.$app-border-strong;
    background: palette.$decent-background;
  }

  &__track-value {
    height: 100%;
    background: palette.$accent;
  }
}
