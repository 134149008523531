@use '../../sass/variables/palette.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/font-style.scss';

.person {
  background: palette.$highlight-background;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  height: 24px;
  border-radius: 12px;

  &__icon {
    margin-right: spacing.$tiny;
    color: palette.$highlight-icon;
  }

  &__label {
    @include font-style.ui($size: small, $bolder: true);
    color: palette.$highlight-text;
    padding-right: spacing.$small;
    white-space: nowrap;
  }
}