@use 'sass:color';
@use '../../sass/variables/palette.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/auto-color.scss';

.status {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  height: 24px;
  border-radius: 12px;

  @include auto-color.status using ($name, $color) {
    &--#{$name} {
      background: $color;
    }
  };

  &__icon {
    margin-right: spacing.$tiny;
    color: palette.$text-primary-on-dark;
  }

  &__label {
    @include font-style.ui($size: small, $background: dark, $bolder: true);
    padding-right: spacing.$small;
    white-space: nowrap;
  }
}