.app {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  &__header {
    flex: 0 0 auto;
  }

  &__content {
    flex: 1 1 0;
    height: 100%;
    overflow: hidden;
  }

  &__router {
    height: 100%;
  }
}