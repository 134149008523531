.file-preview {
  height: 100%;

  &__iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .lds-spinner{
    transform: scale(0.3);
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.file-preview-search-active{
  height: calc(100% - 30px)
}
