@use "../../sass/variables/palette.scss";
@use "../../sass/variables/spacing.scss";

.image-preview {
  max-height: 100%;
  display: flex;
  
  &__image-container {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: spacing.$small;
    background-color: palette.$decent-background;
  }

  &__image {
    flex: 0 1 auto;
    max-height: 100%;
  }
}