@use '../../sass/mixins/focus-ring.scss' as *;
@use '../../sass/variables/palette.scss';
@use '../../sass/variables/spacing.scss';

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  outline: none;

  &:not(:last-child) {
    margin-bottom: spacing.$big;
  }

  &--is-disabled {
    opacity: 0.5;
  }

  &__input {
    position: absolute;
    left: 5px;
    top: 5px;
    opacity: 0;
    box-sizing: border-box;
    z-index: -1;
  }
  
  &__border {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border: 2px solid palette.$form-border;
    border-radius: 4px;
  }

  @include focus-ring($selector: '&__border');

  &--is-checked &__border {
    border-color: palette.$accent;
    background: palette.$accent;
  }

  &__icon {
    color: palette.$text-primary-on-dark;
  }

  &__label {
    flex: 1 1 auto;
    padding-left: spacing.$icon-text;
    padding-right: spacing.$default;
  }
}
