@use '../../sass/variables/box-shadow.scss';
@use '../../sass/variables/border-radius.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/variables/size.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/axis.scss';

.centered-layer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &--vertical-top {
    justify-content: start;
    padding-top: spacing.$huge;
  }

  &__content-box {
    display: flex;
    flex-direction: column;
    background: palette.$layer-background;
    box-shadow: box-shadow.$level-18;
    border-radius: border-radius.$default;
    max-width: calc(100% - #{spacing.$huge});
    max-height: 90vh;
  }

  &__content-flat {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - #{spacing.$huge});
    max-height: 90vh;
  }

  &--size-small &__content-box,
  &--size-small &__content-flat {
    width: 400px;
  }

  &--size-normal &__content-box,
  &--size-normal &__content-flat {
    width: 600px;
  }

  &--size-big &__content-box,
  &--size-big &__content-flat {
    width: 800px;
  }

  &--size-full &__content-box,
  &--size-full &__content-flat {
    width: calc(100% - 40px);
  }

  &--padding-normal &__content-box,
  &--padding-normal &__content-flat {
    padding-top: spacing.$huge;
    padding-bottom: spacing.$huge;
  }

  &--padding-normal &__content-header,
  &--padding-normal &__content-body,
  &--padding-normal &__content-footer {
    @include axis.horizontal(spacing.$huge, padding);
  }

  &--padding-small &__content-box,
  &--padding-small &__content-flat {
    padding-top: spacing.$big;
    padding-bottom: spacing.$big;
  }

  &--padding-small &__content-header,
  &--padding-small &__content-body,
  &--padding-small &__content-footer {
    @include axis.horizontal(spacing.$big, padding);
  }

  &--padding-none &__content-header,
  &--padding-none &__content-body,
  &--padding-none &__content-footer {
    padding: 0;
  }

  &__content-header {
    flex: 0 0 auto;
  }

  &__content-body {
    flex: 1 1 auto;
    overflow: auto;
    @include axis.vertical(spacing.$tiny, padding);
  }

  &__content-footer {
    flex: 0 0 auto;
    .buttons {
      display: flex;
      margin-top: spacing.$big;

      & > button:not(:first-child) {
        margin-left: spacing.$small;
      }
    }
  }
}
