@use '../../sass/variables/palette.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/font-style.scss';

.form {
  display: block;

  &__field.error {

    input {
      padding: spacing.$tiny;
      border: 1px solid palette.$error-on-dark;
    }

    label {
      color: palette.$error-on-dark;
    }

    .form__info {
      background-color: palette.$error;
      color: #ffffff;
      padding: spacing.$tiny;
      border: 1px solid palette.$error-on-dark;
      font-size: 11px;
      border-radius: 3px;
      margin-top: 1px;
    }

  }
}
