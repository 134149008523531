////
/// Generate directional CSS rules with one value (`$value`).
///
/// @example
///   .class {
///     @include horizontal(10px);
///   }
///   outputs:
///   .class {
///     left: 10px;
///     right: 10px;
///   }
///
/// @example
///   .class {
///     @include horizontal(10px, padding);
///   }
///   outputs:
///   .class {
///     padding-left: 10px;
///     padding-right: 10px;
///   }
////

/// @output CSS rules `left` and `right`.
@mixin horizontal($value, $property: null) {
  @if $property != null {
    #{$property}-left: $value;
    #{$property}-right: $value;
  } @else {
    left: $value;
    right: $value;
  }
}

/// @output CSS rules `top` and `bottom`.
@mixin vertical($value, $property: null) {
  @if $property != null {
    #{$property}-top: $value;
    #{$property}-bottom: $value;
  } @else {
    top: $value;
    bottom: $value;
  }
}

/// @output CSS rules `top`, `left`, `right` and `bottom`.
@mixin all($value, $property: null) {
  @if $property != null {
    #{$property}-top: $value;
    #{$property}-left: $value;
    #{$property}-right: $value;
    #{$property}-bottom: $value;
  } @else {
    top: $value;
    left: $value;
    right: $value;
    bottom: $value;
  }
}
