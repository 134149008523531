@use 'sfportal_sass_variables/font-weight.scss';
@use 'sfportal_sass_variables/size.scss';
@use 'sfportal_sass_variables/spacing.scss';

.app-header-logo {
  display: flex;
  align-items: center;
  height: 100%;

  &__image {
    height: size.$logo-height;
    margin-right: spacing.$default;
  }

  &__text {
    white-space: nowrap;
    color: white;
    font-size: 18px;
    line-height: size.$logo-height;
    font-weight: font-weight.$semi-bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
