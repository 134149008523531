@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/border-radius.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/focus-ring.scss' as *;

.textarea {
  display: block;

  &:not(:last-child) {
    margin-bottom: spacing.$big;
  }

  &__label {
    @include font-style.label();

    display: block;
    margin-bottom: spacing.$tiny;
  }

  &--is-error &__label {
    @include font-style.label($error: true);
  }

  &__input {
    @include focus-ring();
    @include font-style.form-element();
    
    width: 100%;
    min-height: 160px;
    resize: vertical;
    @include axis.horizontal(spacing.$small, padding);
    @include axis.vertical(spacing.$tiny, padding);
    border-radius: border-radius.$small;
    border: 1px solid palette.$form-border;
  }
}