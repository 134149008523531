@use '../sass/variables/palette.scss';

.home-view {
  height: 100%;
  
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__tabs {
    flex: 0 0 auto;
  }

  &__content {
    flex: 1 1 0;
    overflow: auto;
  }

  &__content > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: palette.$view-background;
  }
}