@use '../../../../sass/variables/spacing.scss';
@use '../../../../sass/variables/palette.scss';
@use '../../../../sass/mixins/font-style.scss';
@use '../../../../sass/mixins/axis.scss';

.product-detail-view\/product-tree-detail {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100%;
  border-left: 1px solid palette.$app-border-strong;

  &__title {
    @include font-style.ui($size: big);
    @include axis.horizontal(spacing.$default, padding);
    @include axis.vertical(spacing.$default, padding);
    flex: 0 0 auto;
  }
}
.tree-body{
  &__search-active{
    height:calc(100% - 25px) !important;
  }
}
