@use '../../sass/variables/border-radius.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/focus-ring.scss' as *;

.toolbar-button {
  display: flex;
  @include axis.vertical(spacing.$tiny, padding);

  &:not(:first-child) {
    margin-left: spacing.$tiny;
  }

  &__button {
    display: flex;
    align-items: center;
    @include axis.vertical(spacing.$tiny, padding);
    @include axis.horizontal(spacing.$small, padding);
    background: palette.$toolbar-button-background;
    border: 1px solid palette.$toolbar-button-border;
    border-radius: border-radius.$small;
  }

  &__button:not(:disabled):hover {
    background: palette.$toolbar-button-background-hover;
    border-color: palette.$toolbar-button-border-hover;
  }

  &__button:not(:disabled):active {
    background: palette.$toolbar-button-background-active;
    border-color: palette.$toolbar-button-border-active;
  }

  &__button:not(:disabled):focus {
    border-color: palette.$toolbar-button-border-focus;
  }

  &__button {
    @include focus-ring();
  }

  &__button:disabled {
    opacity: 0.5;
  }

  &--type-back &__button {
    border-color: palette.$toolbar-button-border--type-back;
    background: palette.$toolbar-button-background--type-back;
    color: palette.$toolbar-button-color--type-back;
  }

  &--type-back &__button:not(:disabled):hover {
    background: palette.$toolbar-button-background-hover--type-back;
    border-color: palette.$toolbar-button-border-hover--type-back;
  }
  
  &--type-back &__button:not(:disabled):active {
    background: palette.$toolbar-button-background-active--type-back;
    border-color: palette.$toolbar-button-border-active--type-back;
  }

  &--type-back &__button {
    // Focus-Ring am Ende anwenden, damit dieser Vorrang gegenüber
    // `:hover` und `:active` hat.
    @include focus-ring();
  }

  &__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__label {
    @include font-style.ui($size: small, $bolder: true);
    line-height: 24px;
    text-align: left;
    margin-left: spacing.$icon-text;
  }

  &--type-back &__label {
    color: white;
  }
}