@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/variables/spacing.scss';

.toolbar-label {
  display: flex;
  align-items: center;
  @include axis.horizontal(spacing.$tiny, padding);
  
  &__text {
    @include font-style.ui($size: small, $bolder: true);
  }
}
