@use 'sass:string';
@use '../variables/palette.scss';

@mixin focus-ring($selector: null) {
  $defaultSelector: if(
    $selector != null,
    '& #{$selector}',
    '&'
  );

  $focusSelector: if(
    $selector != null,
    '&:not(:disabled):focus #{$selector}',
    '&:not(:disabled):focus'
  );
  
  #{$defaultSelector} {
    transition: box-shadow 0.1s ease;
    outline: none;
  }
  
  #{$focusSelector} {
    box-shadow: 0 0 1px 2px palette.$focus-ring;
  }
}