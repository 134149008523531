@use '../variables/font-weight.scss';

@mixin -register(
  $font-name,
  $font-path,
  $font-weight: font-weight.$normal,
  $italic: false,
  $format: 'truetype',
) {
  @font-face {
    font-family: $font-name;
    src: url($font-path) format($format);
    font-weight: $font-weight;
    font-style: if($italic, italic, normal);
  }
}

@include -register('Open Sans', '../../assets/fonts/opensans-regular.ttf');

@include -register('Open Sans', '../../assets/fonts/opensans-semibold.ttf',
  font-weight.$semi-bold);

@include -register('Open Sans', '../../assets/fonts/opensans-bold.ttf',
  font-weight.$bold);
