@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/font-weight.scss';
@use '../../sass/variables/palette.scss';

.filter-item {
  margin-bottom: spacing.$big;
  
  &__header {
    display: flex;
    align-items: center;
  }

  &__checkbox {
    margin-right: spacing.$icon-text;
  }
  
  &__title {
    @include font-style.ui($size: normal);
    margin-bottom: 0;
  }

  &--is-enabled &__title {
    @include font-style.ui($size: normal, $bolder: true);
  }

  &__content {
    @include axis.vertical(spacing.$default, padding);
    @include axis.horizontal(24px + spacing.$small, padding);
    display: none;

  }

  &--is-enabled &__content {
    position: relative;
    display: block;
  }

  &--is-enabled &__content::before {
    content: '';
    position: absolute;
    @include axis.vertical(spacing.$small);
    left: spacing.$small;
    width: 2px;
    background: palette.$app-border;
  }

  &--is-enabled#{&}--type-select-list &__content {
    display: flex;
    flex-wrap: wrap;
  }

  &__list-item {
    flex: 0 0 (100% / 3);
    @include axis.vertical(spacing.$tiny, padding);
  }
}
