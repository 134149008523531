@use '../../sass/variables/palette.scss';
@use '../../sass/variables/size.scss';
@use '../../sass/mixins/axis.scss';

.dock-layout {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%;

  &__header {
    flex: 0 0 auto;
  }

  &__body {
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
  }

  &__left {
    flex: 0 0 auto;
  }

  &__center {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  &__right {
    flex: 1 1 0;
  }

  &__footer {
    flex: 0 0 auto;
  }
}