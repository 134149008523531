@use 'sass:color';
@use '../../sass/variables/box-shadow.scss';
@use '../../sass/variables/border-radius.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/variables/size.scss';

.item-view {
  border: solid palette.$app-border;
  border-width: 0 0 1px;
  background: palette.$clear-background;
  background-clip: border-box;
  transition:
    border 0.1s ease-out,
    margin 0.1s ease-out,
    box-shadow 0.1s ease-out;

  &--is-expanded + & {
    border-top-width: 1px;
  }

  &:last-child:not(&--is-expanded) {
    border-bottom: none;
  }

  &--is-unread {
    background: palette.$highlight-background;
  }

  &--is-expanded {
    border: 1px solid palette.$app-border-strong;
    border-radius: border-radius.$small;
    @include axis.horizontal(spacing.$small, margin);
    @include axis.vertical(spacing.$small, margin);
  }

  &__body > & {
    margin-left: spacing.$huge;
    border-left: 1px solid palette.$app-border;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    position: sticky;
    top: 0;
    background: palette.$clear-background;
  }

  &:not(&--is-open) &__header {
    border-bottom: none;
  }

  &--is-open &__header {
    border-bottom: 1px solid palette.$app-border-strong;
    position: sticky;
    top: 0;
  }

  &--is-hoverable &__header:hover {
    background: palette.$clear-background-hover;
  }

  &--is-expanded &__header {
    border-top-left-radius: border-radius.$small;
    border-top-right-radius: border-radius.$small;
  }

  &--is-unread &__header {
    background: palette.$highlight-background;
  }

  &--is-unread#{&}--is-hoverable &__header:hover {
    background: palette.$highlight-background-hover;
  }

  &--is-unread &__title {
    color: palette.$highlight-icon;
  }

  &--is-unread &__description {
    color: palette.$highlight-icon;
  }

  &__action {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @include axis.vertical(spacing.$tiny, padding);
    @include axis.horizontal(spacing.$default, padding);
    @include axis.all(spacing.$tiny, margin);
    opacity: 0.5;
    max-height: size.$iconset-size + 10;
    align-self: center;

    &:not(&--is-enabled) {
      opacity: 0.5;
    }

    &--is-enabled:hover, &:link:hover  {
      background-color: palette.$decent-background-hover;
      opacity: 1;
      cursor: pointer;

    }

    &--is-enabled:active,  &:link:active {
      background-color: palette.$decent-background-active;
      opacity: 1;
      cursor: pointer;
    }
  }

  &--is-unread &__action {
    &--is-enabled:hover {
      background-color: palette.$secondary-background-hover;
    }

    &--is-enabled:active {
      background-color: palette.$secondary-background-active;
    }
  }

  &__action--icon-only {
    @include axis.all(spacing.$tiny, padding);
  }

  &__action--type-toggle {
    margin: 0;
    @include axis.horizontal(spacing.$small, padding);
  }

  &__action--type-link {
    cursor: default;
  }

  &__action-icon + &__action-label {
    padding-left: spacing.$icon-text;
  }

  &__action-label {
    @include font-style.ui($size: small, $bolder: true);
  }

  &__read-status-icon {
    color: palette.$decent-icon;
  }

  &--is-unread &__read-status-icon {
    color: palette.$highlight-icon;
  }

  &__header-content {
    flex: 1 1 auto;
    display: flex;
    align-self: stretch;
    align-items: center;
  }

  &__text-area {
    flex: 1 1 auto;
    word-break: break-word;
    @include axis.vertical(spacing.$tiny, padding);
    @include axis.horizontal(spacing.$default, padding);
  }

  &__progress-col {
    flex: 0 0 auto;
    @include axis.vertical(spacing.$tiny, padding);
    @include axis.horizontal(spacing.$default, padding);
  }

  &__progress-text {
    @include font-style.ui($size: small);
  }

  &__icon {
    flex: 0 0 auto;
    padding-left: spacing.$default;
  }

  &__title {
    display: flex;
    align-items: center;
    @include font-style.ui($size: normal, $bolder: true);
  }

  &__title-text {
    flex: 1 1 auto;
  }

  &__description {
    @include font-style.ui($size: small);
  }

  &__timestamp-col {
    flex: 0 0 auto;
    @include axis.vertical(spacing.$tiny, padding);
    @include axis.horizontal(spacing.$default, padding);
  }

  &__timestamp {
    @include font-style.ui($size: small, $bolder: true, $color: passive);
  }

  &--is-unread &__timestamp {
    color: palette.$highlight-icon;
  }

  &__actions-col {
    flex: 0 0 auto;
  }

  &__body {
    display: none;
    word-break: break-word;
  }

  &__body > :first-child,
  &__body > :first-child > :first-child {
    margin-top: 0;
  }

  &--is-open &__body {
    display: block;
  }

  &__body> :first-child {
    margin-top: 0;
  }
}
