@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/variables/size.scss';

.menu-item {
  display: flex;
  align-items: center;
  cursor: default;

  &:first-child {
    margin-top: spacing.$tiny;
  }

  &:last-child {
    margin-bottom: spacing.$tiny;
  }

  &:not(&--is-disabled):hover {
    background: palette.$decent-background-hover;
  }

  &:not(&--is-disabled):active {
    background: palette.$decent-background-active;
  }

  &--is-disabled {
    opacity: 0.5;
  }

  &__icon-container {
    padding-left: spacing.$small;
  }

  &__dummy-icon {
    width: size.$iconset-size;
    height: size.$iconset-size;
  }

  &__icon-container + &__label {
    margin-left: spacing.$icon-text;
  }
  
  &__label {
    @include font-style.ui($size: small);
    @include axis.vertical(spacing.$tiny, padding);
    margin-right: spacing.$big;
    line-height: size.$iconset-size;
  }

  &__label:first-child {
    margin-left: spacing.$small;
  }

  &--highlight &__label {
    @include font-style.ui($size: small, $bolder: true);
  }
}
