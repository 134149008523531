@use '../../sass/variables/palette.scss';
@use '../../sass/variables/size.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/counter-label.scss' as *;

.app-header-button {
  display: flex;

  &__button {
    @include font-style.ui($background: dark, $size: small);
    @include axis.horizontal(spacing.$default, padding);
    height: 100%;
    display: flex;
    align-items: center;
    border: none;
    border-left: 1px solid palette.$app-border-on-dark;
    background: palette.$brand-secondary;
    cursor: default;

    // Erster Button im linken Bereich soll keinen Border links erhalten.
    // Sieht nämlich komisch aus.
    @at-root .app-header__app-menu-area > :first-child & {
      border-left: none;
    }
  }

  &--current &__button {
    @include font-style.ui($background: light, $size: small);
    background-color: palette.$app-background;
    border-bottom: 3px solid palette.$brand;
  }

  &--hoverable:not(&--current) &__button:hover {
    background-color: palette.$brand-secondary--hover;
  }

  &--hoverable:not(&--current) &__button:active {
    background-color: palette.$brand-secondary--active;
  }

  &__icon + &__text {
    margin-left: spacing.$icon-text;
  }

  &__counter {
    @include counter-label($background: palette.$accent);
  }

  &--current &__counter {
    @include counter-label($background: palette.$accent-background);
  }

  &__label + &__counter {
    margin-left: spacing.$default;
  }
}
