.app-header-dropdown {
  display: flex;

  &__button {
    display: flex;
  }
  
  &__dropdown {
    display: none;
  }

  &--is-open &__dropdown {
    display: block;
  }
}