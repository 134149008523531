@use '../../sass/mixins/font-style.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/variables/border-radius.scss';

.list-item-group {
  &:not(:first-child) {
    margin-top: spacing.$small;
  }
  
  &__header {
    display: flex;
    align-items: center;
  }

  &--is-clickable &__header:hover {
    background-color: palette.$clear-background-hover;
  }

  &--is-highlighted &__header {
    background-color: palette.$highlight-background;
    box-shadow: 3px 0 0 palette.$highlight-border inset;
  }

  &--is-highlighted#{&}--is-clickable &__header:hover {
    background-color: palette.$highlight-background-hover;
  }

  &__open-indicator-container {
    flex: 0 0 auto;
    @include axis.vertical(spacing.$small, padding);
    padding-left: spacing.$small;
    padding-right: spacing.$icon-text;
  }

  &__title {
    flex: 1 1 auto;
    @include font-style.ui($bolder: true);
    @include axis.vertical(spacing.$small, padding);
  }

  &--is-highlighted &__title {
    color: palette.$highlight-text;
  }

  &__title-suffix {
    @include font-style.ui($color: passive);
  }

  &__actions {
    flex: 0 0 auto;
    @include axis.vertical(spacing.$tiny, padding);
    padding-right: spacing.$small;
  }

  &__body {
    display: none;
    padding-left: (24px + 2 * spacing.$small);
  }

  &--is-open &__body {
    display: block;
  }
}