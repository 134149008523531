@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';
@use '../../sass/variables/border-radius.scss';
@use '../../sass/variables/spacing.scss';

.modal {
  position: absolute;
  @include axis.all(0);
  z-index: 10;
  background: hsla(0, 0%, 100%, 0.8);

  &__title {
    @include font-style.heading($level: 2);
    margin-bottom: spacing.$default;
  }

  &__headline {
    display: flex;
    align-items: center;
    @include font-style.heading($level: 3);
  }

  &__headline-icon {
    margin-right: spacing.$icon-text;
  }
}