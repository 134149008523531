@use '../../sass/mixins/font-style.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/variables/palette.scss';

.empty-indicator {
  text-align: center;
  padding: spacing.$huge;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &--is-compact {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: spacing.$default;
    text-align: left;
  }

  &--is-stretching {
    height: 100%;
  }

  &--is-dimmed {
    background-color: palette.$decent-background;
  }

  &__icon {
    color: palette.$big-icon;
    margin-bottom: spacing.$big;
  }

  &__text {
    @include font-style.ui($size: big, $bolder: true, $color: passive);
    text-align: center;
  }

  &--is-compact &__text {
    @include font-style.ui($color: passive);
  }
}