@use 'sass:color';
@import 'sfportal_sass_variables_overridable/palette';

$brand-secondary: hsl(60, 4%, 20%);
$brand-secondary--hover: hsl(60, 4%, 30%);
$brand-secondary--active: hsl(60, 4%, 10%);
$brand-secondary--current: hsl(60, 4%, 40%);

$accent: hsl(214, 60%, 55%);
$accent-hover: hsl(214, 60%, 65%);
$accent-active: hsl(214, 60%, 75%);
$accent-background: hsl(214, 60%, 45%);

/// Die Nummern entsprechen den IDs der Stati in der Datenbank.
/// Die Zuordnungen können im API-Aufruf `/api/values` nachgelesen werden.
$status: (
  1: hsl(40, 90%, 45%), // Angelegt
  2: hsl(20, 90%, 45%), // Bearbeitung
  3: hsl(200, 80%, 45%), // Assets-freigegeben
  4: hsl(290, 80%, 40%), // Export An Dienstleister
  5: hsl(300, 80%, 40%), // Prüfung-herstellung
  6: hsl(180, 80%, 40%), // Prüfung Lektor
  7: hsl(220, 80%, 40%), // Freigegeben
  8: hsl(140, 80%, 40%), // Abgeschlossen
  9: hsl(330, 80%, 50%), // Gesamtumbruch
  10: hsl(0, 80%, 45%), // Abgelehnt
  11: hsl(40, 60%, 45%), // Vorbereitung
);

$error: hsl(0, 80%, 45%);
$error-on-dark: hsl(0, 80%, 55%);

$dark-background: hsl(0, 0%, 30%);

$secondary-background: hsla(0, 0%, 0%, 0);
$secondary-background-hover: color.change($accent, $alpha: 0.1);
$secondary-background-active: color.change($accent, $alpha: 0.2);

$decent-background: hsl(0, 0%, 95%);
$decent-background-hover: hsla(0, 0%, 0%, 0.1);
$decent-background-active: hsl(0, 0%, 0%, 0.2);
$decent-text: hsl(0, 0%, 20%);
$decent-icon: hsl(0, 0%, 50%);

$big-icon: hsl(0, 0%, 80%);

$clear-background: hsla(0, 0%, 0%, 0);
$clear-background-hover: hsla(0, 0%, 0%, 0.05);
$clear-background-active: hsla(0, 0%, 0%, 0.12);

$highlight-background: hsl(214, 100%, 95%);
$highlight-background-hover: hsl(214, 100%, 90%);
$highlight-background-active: hsl(214, 100%, 80%);
$highlight-border: hsl(214, 100%, 70%);
$highlight-text: hsl(214, 100%, 30%);
$highlight-icon: hsl(214, 100%, 30%);

$app-background: hsl(0, 0%, 98%);
$app-border: hsl(0, 0%, 85%);
$app-border-strong: hsl(0, 0%, 70%);
$app-border-on-dark: hsl(0, 0%, 40%);
$view-background: hsl(0, 0%, 100%);
$layer-background: hsl(0, 0%, 100%);
$layer-background-dim: hsl(0, 0%, 97%);

$form-border: hsl(0, 0%, 60%);
$table-border: hsl(0, 0%, 80%);
$focus-ring: color.change($accent, $alpha: 0.5);

$text-primary: hsl(0, 0%, 5%);
$text-primary-hover: $accent-hover;
$text-primary-on-dark: hsl(0, 0%, 100%);

$text-passive: hsl(0, 0%, 50%);
$text-passive-on-dark: hsl(0, 0%, 90%);

$text-disabled: hsla(0, 0%, 0%, 0.35);
$text-disabled-on-dark: hsl(0, 0%, 50%);

// Buttons
$button-border--type-primary: $accent;
$button-background--type-primary: $accent;
$button-border-hover--type-primary: $accent;
$button-background-hover--type-primary: $accent-hover;
$button-border-active--type-primary: $accent;
$button-background-active--type-primary: $accent-active;
$button-border-focus--type-primary: white;

$button-border--type-secondary: $accent;
$button-background--type-secondary: $secondary-background;
$button-border-hover--type-secondary: $accent;
$button-background-hover--type-secondary: $secondary-background-hover;
$button-border-active--type-secondary: $accent;
$button-background-active--type-secondary: $secondary-background-active;

$button-background--type-link: hsla(0, 0%, 100%, 0);
$button-color--type-link: $accent;
$button-color-hover--type-link: $accent-hover;
$button-color-active--type-link: $accent-active;

// Toolbar Buttons
$toolbar-button-border: hsl(0, 0%, 80%);
$toolbar-button-background: hsl(0, 0%, 95%);
$toolbar-button-border-hover: hsl(0, 0%, 80%);
$toolbar-button-background-hover: hsl(0, 0%, 90%);
$toolbar-button-border-active: hsl(0, 0%, 80%);
$toolbar-button-background-active: hsl(0, 0%, 85%);
$toolbar-button-border-focus: $accent;

$toolbar-button-border--type-back: $accent;
$toolbar-button-background--type-back: $accent;
$toolbar-button-color--type-back: $text-primary-on-dark;
$toolbar-button-border-hover--type-back: $accent;
$toolbar-button-background-hover--type-back: $accent-hover;
$toolbar-button-border-active--type-back: $accent;
$toolbar-button-background-active--type-back: $accent-active;
