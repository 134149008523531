@use 'src/sass/variables/spacing';

.asset-form {
  .form__field  {
    margin-bottom: spacing.$tiny;
  }
  .buttons {
    display: flex;
    margin-top: spacing.$big;

    & > button:not(:first-child) {
      margin-left: spacing.$small;
    }
  }

}
