@use '../../sass/mixins/font-style.scss';
@use '../../sass/variables/spacing.scss';

.comment {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  &:not(:last-child) {
    margin-bottom: spacing.$big;
  }
  
  &__username {
    flex: 0 0 0%;
    white-space: nowrap;
    margin-right: spacing.$small;
    @include font-style.ui($bolder: true);
    user-select: text;
  }
  
  &__date {
    flex: 1 0 0%;
    @include font-style.ui($size: small, $color: passive);
    user-select: text;
  }
  
  &__body {
    flex: 1 1 100%;
    @include font-style.ui();
    margin-top: spacing.$tiny;
    user-select: text;
  }

  &__body-line {
    margin: 0;
  }

  &__body-line:not(:last-child) {
    margin-bottom: spacing.$default;
  }
}