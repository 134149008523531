@use '../../sass/variables/palette.scss';


.workflow-list {
  height: calc(100% - 50px);
  overflow-y: auto;
}

.workflowItem {
  display: flex;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;

  &:hover {
    background-color: palette.$decent-background-hover;
  }

  .icon-container {
    width: 130px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    margin: 0 10px;
    align-items: center;

    .icon-hover {
      padding: 5px;

      &:hover {
        background-color: palette.$decent-background-hover;
      }
    }
  }

  .spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: #00000045;

    .lds-spinner {
      zoom: 60%;
    }

    .lds-spinner div:after {
      background: #fff;
    }
  }
}

.workflow-info {
  display: flex;
  padding: 5px;
  width: calc(100% - 150px);
  flex-direction: row;
  align-items: center;

  .data-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 275px);
  }

  .ellipse-identifier {
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }

  .progress-bar__track-value {
    background-color: #f39200;
  }

  .item-view__progress-col {
    width: 275px;
  }
}

.uploadItem {
  display: flex;
  padding: 5px;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;

  &:hover {
    background-color: palette.$decent-background-hover;
  }
}