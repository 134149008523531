@use '../../sass/variables/palette.scss';

.tabs {
  display: flex;
  box-shadow: 0 -1px 0 palette.$app-border inset;

  &--type-normal {
    padding-top: 5px;
  }
}
