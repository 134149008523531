@use '../variables/palette.scss';
@use '../variables/spacing.scss';
@use '../mixins/axis.scss';

* {
  box-sizing: border-box;
}

html {
  background: palette.$app-background;
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  user-select: none;
  overflow: hidden;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

svg {
  vertical-align: middle;
  fill: currentColor;
}

a {
  color: inherit;
  text-decoration: none;
}

td,
th {
  padding: 0;
}

hr {
  border: none;
  width: 100%;
  height: 1px;
  background: palette.$app-border;
}