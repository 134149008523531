@use '../../sass/variables/palette.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/axis.scss';

.toolbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  z-index: 1;
  @include axis.horizontal(spacing.$tiny, padding);
  border-bottom: 1px solid palette.$app-border-strong;

  &__right-align {
    margin-left: auto;
  }
}