@use '../../sass/variables/box-shadow.scss';
@use '../../sass/variables/border-radius.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/variables/size.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';

.loading-container, .error-container {
  position: relative;
  flex: 0 1 auto;

  &--is-stretching {
    flex: 1 1 auto;
  }

  &__indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: spacing.$big;
    z-index: 1;
  }

  &__window {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: spacing.$big;
    background: palette.$layer-background;
    border: 1px solid palette.$app-border;
    border-radius: border-radius.$default;
  }

  &__text {
    @include font-style.ui();
    margin-bottom: spacing.$default;
  }

  &__content {
    height: 100%;
    overflow: auto;
  }

  &--is-loading &__content {
    display: none;
  }
}
