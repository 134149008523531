@use '../../sass/variables/palette.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/mixins/font-style.scss';

.tabular-data {
  &__table {
    border-collapse: collapse;
  }

  &__heading {
    @include font-style.ui($bolder: true, $color: 'passive');
  }

  &__key,
  &__value {
    @include axis.vertical(spacing.$tiny, 'padding');
    user-select: text;
  }
  
  &__key {
    text-align: left;
  }

  &__value {
    @include font-style.ui();
    padding-left: spacing.$default;
  }
}