@use '../../sass/variables/spacing.scss';

.filter-modal {
  &__buttons {
    display: flex;
    margin-top: spacing.$big;
  }

  &__buttons > button:not(:first-child) {
    margin-left: spacing.$small;
  }
}
