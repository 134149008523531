@use '../../sass/mixins/font-style.scss';
@use '../../sass/variables/palette.scss';
@use '../../sass/mixins/axis.scss';
@use '../../sass/variables/spacing.scss';
@use '../../sass/mixins/counter-label.scss' as *;

.app-tabs {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;

  &__tab-list {
    display: flex;
    border-bottom: 1px solid palette.$app-border;
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
  }

  &__tab {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    @include axis.horizontal(spacing.$default, padding);
    @include axis.vertical(spacing.$small, padding);
    @include font-style.ui($size: small, $bolder: true, $color: passive);
  }

  &__tab:not(&__tab--current):hover {
    @include font-style.ui($size: small, $bolder: true);
  }

  &__tab--current {
    border-bottom: 2px solid palette.$brand;
    @include font-style.ui($size: small, $bolder: true);
  }

  &__tab-icon {
    fill: palette.$brand;
    margin-right: spacing.$icon-text;
  }

  &__tab-text {
  }

  &__tab-counter {
    @include counter-label($background: palette.$accent);
    margin-left: spacing.$icon-text;
  }

  &__tab--current &__tab-text {
  }

  &__tab-panels {
    display: grid;
  }

  &__tab-panel {
    display: grid;
  }
}
